import { DOCUMENT } from '@angular/common';
import { Component, DestroyRef, OnInit, computed, effect, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  MembershipReadOnlyControllerService,
  OrganizationReadOnlyControllerService
} from '@garmin-avcloud/avcloud-fly-web-common/api/orgs-roles/read';
import { GarminGlobalStoreService } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { CustomerService } from '@garmin-avcloud/avcloud-web-utils';
import { of, switchMap } from 'rxjs';
import { UserConfig, UserConfigService } from 'src/app/core/services/config/user-config.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'fly-standard-layout',
  templateUrl: './standard-layout.component.html',
  styleUrls: ['./standard-layout.component.scss']
})
export class StandardLayoutComponent implements OnInit {
  private readonly document = inject(DOCUMENT);
  private readonly garminGlobalStoreService = inject(GarminGlobalStoreService);
  private readonly userConfigService = inject(UserConfigService);
  private readonly organizationReadonlyControllerService = inject(OrganizationReadOnlyControllerService);
  private readonly membershipReadOnlyControllerService = inject(MembershipReadOnlyControllerService);
  private readonly customerService = inject(CustomerService);
  private readonly destroyRef = inject(DestroyRef);

  userConfig: UserConfig | null = null;
  orgName: string | null = null;
  customer: { name: string; email: string } | null = null;
  membershipCount = 0;

  private readonly orgUuid = computed(() => {
    const cookies = this.garminGlobalStoreService.cookies();
    const hasCookie = 'global' in cookies && 'org' in cookies['global'] && 'uuid' in cookies['global']['org'];
    return hasCookie && cookies['global']['org']['uuid'] !== '' ? (cookies['global']['org']['uuid'] as string) : null;
  });

  constructor() {
    effect(() => {
      const orgUuid = this.orgUuid();
      this.membershipReadOnlyControllerService
        .getMemberships()
        .pipe(
          switchMap((memberships) => {
            this.membershipCount = memberships.length;

            if (orgUuid != null && memberships.find((membership) => membership.organizationUuid === orgUuid) != null) {
              return this.organizationReadonlyControllerService.getOrganization(orgUuid);
            } else {
              this.garminGlobalStoreService.removeGlobal('org');
              return of(null);
            }
          })
        )
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((org) => {
          this.orgName = org?.name ?? null;
        });
    });
  }

  ngOnInit(): void {
    this.userConfigService
      .getUserConfig()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((userConfig) => {
        this.userConfig = userConfig;
      });

    this.customerService
      .getCustomerAccount()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((customerAccount) => {
        this.customer = {
          name: `${customerAccount.firstName} ${customerAccount.lastName}`,
          email: customerAccount.primaryEmailAddress
        };
      });

    this.loadTrustArcScript();
  }

  private loadTrustArcScript(): void {
    const body = this.document.body as HTMLBodyElement;

    const script: HTMLScriptElement = this.document.createElement('script');
    script.src = environment.trustArcSrc;
    script.type = 'text/javascript';
    script.crossOrigin = '';

    body.appendChild(script);
  }
}
